<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

import Logo from '../Logo/Logo.vue'
import Strapline from '@/Components/Global/Footer/Strapline.vue'

const page = usePage()
const user = computed(() => page.props.auth.user)
const showAdverts = computed(() => !user.value?.is_subscriber ?? true)

</script>

<template>
    <footer class="w-footer u-dark-theme">
        <div class="w-footer__logo-wrapper">
            <Logo class="w-footer__logo" />
        </div>

        <div class="w-footer__container">
            <span v-if="!showAdverts" class="w-footer__strapline">Thank you for supporting <a class="w-footer__nav-item" href="https://www.brfcs.com">BRFCS.com</a></span>
            <span class="w-footer__strapline"><Strapline /></span>
            <span class="w-footer__legal">&copy; Northern Horde Ltd, Company Registration Number: 13467840.
        <br> Registered in England and Wales. All Rights Reserved.</span>
            <nav class="w-footer__nav">
                <a class="w-footer__nav-item" href="/privacy">Privacy Policy</a>
                <a class="w-footer__nav-item" href="/pages/about-us">About Us</a>
                <a class="w-footer__nav-item" href="/magazine/articles/feed.xml">RSS Feed</a>
            </nav>
        </div>

    </footer>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-footer {
    $this: &;
    display: flex;
    gap: 1.2rem;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    padding: 3.6rem 2.4rem 2.4rem;
    transition: all ease-out 0.2s;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: .8rem;
        background: var(--g-gradient-primary-secondary);
        z-index: var(--z-index-one);
    }

    &__logo-wrapper {
        position: relative;
        z-index: var(--z-index-two);
        transition: all ease-out 0.2s;
        width: 9rem;
        height: 9rem;
        margin: 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: .8rem;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &__nav {
        display: flex;
        gap: .8rem;
    }

    &__nav-item {
        @include m.sm-bold;
        color: var(--g-color-secondary);
        text-decoration: none;
        transition: color ease-out 0.2s;

        &:hover {
            color: var(--g-color-primary);
        }
    }

    &__legal {
        @include m.xsm;
        color: var(--g-color-gray-10);
        text-align: right;
    }

    &__strapline {
        @include m.sm-bold;
        color: var(--g-color-gray-10);
    }

    @media (width < m.$mobile) {
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;
        justify-content: center;
        padding: 3.6rem 1.2rem;

        &__logo-wrapper {
            width: 5.4rem;
            height: 5.4rem;
            margin: 0 .8rem;
        }

        &__container {
            align-items: center;
            justify-content: center;
        }

        &__legal {
            text-align: center;
        }
    }

}
</style>

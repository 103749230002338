<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const page = usePage();
const user = computed(() => page.props.auth.user);
const showAdverts = computed(() => !user.value?.is_subscriber ?? true);

if(showAdverts.value) {
    (function() {
        var site_id = 2722;
        var data_site_id = 0;

        var sn_cb = new Date().getMonth();
        var snack_hb = document.createElement('script');
        snack_hb.src =
            'https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/' +
            site_id +
            '?cb=' +
            sn_cb;
        snack_hb.id = 'snack_ads';
        if (data_site_id) {
            snack_hb.setAttribute('data-site-id', data_site_id);
        }
        document.body.appendChild(snack_hb);
        snack_hb.onerror = function() {
            document.body.removeChild(snack_hb);
            var snack_hb2 = document.createElement('script');
            snack_hb2.src =
                'https://cdn2-header-bidding.snack-media.com/assets/js/snack-loader/' +
                site_id +
                '?cb=' +
                sn_cb;
            snack_hb2.id = 'snack_ads';
            if (data_site_id) {
                snack_hb2.setAttribute('data-site-id', data_site_id);
            }
            document.body.appendChild(snack_hb2);
        };
    })();
}
</script>

<template>
    <div v-if="showAdverts" id="snack_dex1"></div>
</template>

<template>
    <a href="/" class="o-logo">
        <img class="o-logo__img" src="/images/logos/brfcs.svg" alt="BRFCS.com" />
    </a>
</template>

<style scoped>
    .o-logo {
        display: block;
        width: 100%;
        height: 100%;
    }

    .o-logo__img {
        aspect-ratio: 121 / 118;
    }
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'

import Logo from '../Logo/Logo.vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import FlyoutNav from './components/FlyoutNav.vue'
import UserSummary from '@/Components/Common/UserSummary.vue'
import { usePage } from '@inertiajs/vue3'
import Strapline from '@/Components/Global/Footer/Strapline.vue'

const page = usePage()
const user = computed(() => page.props.auth?.user)
const menuOpen = ref(false)

// If the screen width is greater than 680px...
if (window?.innerWidth > 680) {
    // Add a scroll listener to the window to apply the active state to the header
    document.addEventListener('scroll', (e) => {
        // if the scroll is greater than 100px, add a class to the header
        const header = document.querySelector('.w-header')

        if (header) {
            if (window.scrollY > 100) {
                header.classList.add('w-header--active')
            } else {
                header.classList.remove('w-header--active')
            }
        }
    })
}

</script>

<template>
    <header class="w-header u-dark-theme">
        <span class="w-header__strapline"><Strapline /></span>
        <div class="w-header__logo-wrapper">
            <Logo class="w-header__logo" />
        </div>
        <nav class="w-header__nav-container">
            <ul>
                <li>
                    <a href="/articles">Articles</a>
                </li>
                <li>
                    <a href="/fixtures">Fixtures</a>
                </li>
                <li>
                    <a href="https://www.brfcs.com/forums/">Forum</a>
                </li>
                <li>
                    <a :href="route('podcasts.list')">Podcast</a>
                </li>
                <li>
                    <a :href="route('articles.byCategory', { name: '4000 Holes' })">4000 Holes</a>
                </li>
                <li><a :href="route('page', { page: 'premium' })">Subscribe</a></li>
            </ul>
        </nav>
        <div class="w-header__icons">

            <UserSummary :user="user" />
            <Bars3Icon class="w-header__icon" @click="menuOpen = !menuOpen" />
        </div>

        <FlyoutNav :menuOpen="menuOpen" @close-nav="menuOpen = !menuOpen" :user="user" />

    </header>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-header {
    $this: &;
    display: flex;
    gap: 1.2rem;
    position: sticky;
    z-index: var(--z-index-five);
    top: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 9.4rem;
    padding: 3.2rem 1.2rem 0;
    transition: all ease-out 0.2s;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6.2rem;
        background-color: var(--g-color-system-white-trans);
        z-index: var(--z-index-one);
    }

    &__strapline {
        position: absolute;
        top: .8rem;
        right: 1.2rem;
        @include m.sm-bold;
        z-index: -1;
        opacity: 1;
        transition: opacity ease-out 0.2s;
        color: var(--g-color-gray-10);
    }

    &__logo-wrapper {
        position: relative;
        z-index: var(--z-index-two);
        transition: all ease-out 0.2s;
        width: 12rem;
        height: 12rem;
        margin: 0;
    }

    &__nav-container {
        position: relative;
        z-index: var(--z-index-two);
        flex-grow: 1;

        ul {
            display: flex;
            align-items: center;
            gap: 2.4rem;
            list-style: none;
            @include m.h5-bold;
            text-transform: uppercase;
            text-align: center;

            li a {
                @include m.link-reset;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -0.8rem;
                    left: 0;
                    width: 100%;
                    height: 0.4rem;
                    background: var(--g-gradient-primary-secondary);
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform 0.3s;
                }

                @include m.hover {
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    &__icons {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        position: relative;
        z-index: var(--z-index-two);
    }

    &__icon {
        width: 2.4rem;
        height: 2.4rem;
        cursor: pointer;

        @include m.hover {
            animation: wiggle 0.3s;
        }
    }

    &--active {
        padding-top: 0;
        height: 6.2rem;

        &::after {
            height: 6.2rem;
            background-color: transparent;
        }

        #{$this}__logo-wrapper {
            width: 5.4rem;
            height: 5.4rem;
            margin: 0 .8rem;
        }

        #{$this}__strapline {
            opacity: 0;
        }
    }

    @media (width < 860px) {

        #{$this}__logo-wrapper {
            width: 5.4rem;
            height: 5.4rem;
            margin: 0 .8rem;
        }

        &__nav-container {
            display: none;
        }
    }

    @media (width < m.$phablet) {
        padding-top: 0;
        height: 6.2rem;

        &::after {
            height: 6.2rem;
        }

        #{$this}__strapline {
            opacity: 0;
            visibility: hidden;
        }
    }
}
</style>

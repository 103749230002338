<script setup lang="ts">
import { computed } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import Button from '@/Components/Common/Button.vue'

const page = usePage()
const props = defineProps({
    menuOpen: Boolean,
    user: Object,
})

const className = computed(() => {
    return props.menuOpen ? 'w-flyout-nav w-flyout-nav--active' : 'w-flyout-nav'
})

const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
</script>

<template>
    <section :class="className">
        <Transition>
            <nav class="w-flyout-nav__container u-light-theme">
                <header class="w-flyout-nav__header">
                    <XMarkIcon class="w-flyout-nav__close-icon" @click="$emit('closeNav')" />
                </header>
                <ul class="w-flyout-nav__list">
                    <li class="w-flyout-nav__item">
                        <a :href="route('articles.list')" class="w-flyout-nav__link">Articles</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a href="https://www.brfcs.com/forums/" class="w-flyout-nav__link">Forum</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a :href="route('fixtures')" class="w-flyout-nav__link">Fixtures</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a :href="route('results')" class="w-flyout-nav__link">Results</a>
                    </li>
                    <!--                    <li class="w-flyout-nav__item">-->
                    <!--                        <a href="/table" class="w-flyout-nav__link">Table</a>-->
                    <!--                    </li>-->
                    <li class="w-flyout-nav__item">
                        <a :href="route('podcasts.list')" class="w-flyout-nav__link">Podcast</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a :href="route('articles.byCategory', ['4000 Holes'])" class="w-flyout-nav__link">4000
                            Holes</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a href="https://www.brfcs.com/store" class="w-flyout-nav__link">Store</a>
                    </li>
                    <li class="w-flyout-nav__item">
                        <a :href="route('page', ['premium'])" class="w-flyout-nav__link">BRFCS Premium</a>
                    </li>
                    <!--                    <li class="w-flyout-nav__item">-->
                    <!--                        <a href="/about" class="w-flyout-nav__link">About</a>-->
                    <!--                    </li>-->
                    <li class="w-flyout-nav__item" v-if="user">
                        <form method="POST" :action="route('logout')">
                            <input type="hidden" name="_token" :value="csrf_token">
                            <button type="submit" class="w-flyout-nav__link">Log Out</button>
                        </form>
                    </li>
                </ul>
            </nav>
        </Transition>
    </section>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-flyout-nav {
    $this: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-four);
    overflow: hidden;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--g-text-color);

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--g-color-system-dark);
        opacity: 0;
        transition: opacity ease-out 0.2s;
    }

    &--active {
        visibility: visible;

        &::before {
            opacity: .9;
        }

        #{$this}__container {
            transform: translateX(0);
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 2.4rem;
    }

    &__close-icon {
        width: 2.4rem;
        height: 2.4rem;
        color: var(--g-color-system-dark);
        transition: transform ease-out 0.2s;

        @include m.hover {
            transform: rotate(90deg);
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background: var(--g-color-system-white);
        height: 100dvh;
        width: 100%;
        max-width: 56rem;
        transform: translateX(100%);
        transition: transform ease-out 0.2s;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__item {
        display: flex;
        justify-content: center;
    }

    &__link {
        @include m.link-reset;
        @include m.button-reset;
        @include m.h3-bold;
        text-transform: uppercase;

        &:hover {
            color: var(--g-color-primary);
        }
    }
}
</style>

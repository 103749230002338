<script setup lang="ts">
import UserAvatar from './UserAvatar.vue';
import { computed } from 'vue'

interface Props {
  user?: { name: string, avatar: string, url: string; };
}

const props = defineProps<Props>()

const userName = computed(() => {
  const doc = document.createElement('div')
  doc.innerHTML = props.user.name ?? 'Guest'
  return doc.textContent || doc.innerText || ''
})

const loginClickHandler = () => {
  // Store the current path in local storage
  localStorage.setItem('redirectUrl', window.location.href)
  // Redirect to the login page
  window.location.href = '/invision/redirect'
}
</script>

<template>
  <span class="o-user-summary">
    <nav class="" v-if="!user">
      <span class="o-button" @click="loginClickHandler">
        <span class="o-button__text"> Sign In/Up </span>
      </span>
    </nav>
    <nav v-else>
      <a :href="user?.url" class="o-user-summary__container">
        <UserAvatar :userName="userName" :imgSrc="user?.avatar" />
        <span class="o-user-summary__name">{{ userName }}</span>
      </a>
    </nav>
  </span>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-user-summary {
  &__container {
    display: flex;
    align-items: center;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: .8rem;
  }

  &__avatar-container {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -.2rem;
      left: -.2rem;
      width: calc(100% + .4rem);
      height: calc(100% + .4rem);
      border-radius: 50%;
      z-index: -1;
      background: var(--g-gradient-primary-secondary);
    }

    >img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__name {
    @include m.h6-bold;
  }
}
</style>
